import React, { useContext } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";

const useApiAxios = () => {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem("bearer");
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  // You can also set headers for specific request methods
  axios.defaults.headers.get['Accept'] = 'application/json';
  const {
    userData,
    setUserData,
    isAuthorized,
    setIsAuthorized,
    setIsLoading,
    openInfoSnackbar,
    openErrorSnackbar,
    updateCount, setUpdateCount
  } = useContext(Context);
  const ENDPOINT = process.env.REACT_APP_ENDPOINT;

  const CONTACT = "/create/Contact"
  const SIGNUP = "/signup/subAdmin"
  const CHECK_EMAIL = "/check/email"
  
  const GET_ALL_CONTENT_MARKET_OWNED = "/marketSection"
  const GET_ALL_MARKET_CONTENT = "/filterAllMarket";
  const GET_MARKET_VENDOR_FILTER = "/get/vendor/filter";
  

  const navigate = useNavigate();

  const contactApi = async ({ name, organization, phone, email, about, type }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CONTACT}`, { name, organization, phone, email, about, type });
      navigate("/");
      setIsLoading(false);
      openInfoSnackbar(res.data.message)
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };
  const singupApi = async ({ name, organization, phone, email, about }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${SIGNUP}`, { name, organization, phone, email, about });
      navigate("/");
      setIsLoading(false);
      openInfoSnackbar(res.data.message)
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };

  const checkEmailApi = async ({ email }) => {
    try {
      const res = await axios.post(`${ENDPOINT}${CHECK_EMAIL}`, { email });
      return { error: false, data: res.data.message }
    } catch (error) {
   
      return { error: true, data: error.response.data.message }
    } finally {
      setIsLoading(false);
    }
  };

  const getMarketsOwned = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_CONTENT_MARKET_OWNED}`);
			setIsLoading(false);
			console.warn("this is the Content ", res);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};
  const getAllMarketContentApi = async (id) => {
    try {
      setIsLoading(true); // Show loading state
  
      // Make the API request
      const res = await axios.get(`${ENDPOINT}${GET_ALL_MARKET_CONTENT}?id=${id}`);
  
      // After request finishes, hide loading state
      setIsLoading(false);
  
      // Return the response data
      return { error: false, data: res.data };
  
    } catch (error) {
      setIsLoading(false); // Hide loading state if there is an error
  
      // Check for a specific error message structure, otherwise provide a generic message
      const errorMessage = error?.response?.data?.message || "An error occurred while fetching market content.";
  
      // Open the error snackbar with the message
      openErrorSnackbar(errorMessage);
  
      // Return error data with message and any available details
      return {
        error: true,
        data: error?.response?.data || { message: errorMessage },
      };
    }
  };

  const getMarketVendorFilterApi = async (id) => {
    try {
      setIsLoading(true);
      // const res = await axios.get(`${ENDPOINT}${GET_MARKET_VENDOR}`);
      const res = await axios.post(`${ENDPOINT}${GET_MARKET_VENDOR_FILTER}?marketId=${id}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  
  return {
    contactApi,
    singupApi,
    checkEmailApi,
    getMarketsOwned,
    getAllMarketContentApi,
    getMarketVendorFilterApi
  };
};

export default useApiAxios;
