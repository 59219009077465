import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import Loader from "./Component/loader/Loader";
import { Context } from "./context/Context";
import Home from "./Component/landingPage/Home";
import SubAdminSignUp from "./Component/subAdminSignup/SubAdminSignUp";
import SnackbarComponent from "./Component/snackbarComponent/SnackbarComponent";
import MarketOwn from "./Component/marketOwn/MarketOwn";
import MarketOwnerDetail from "./Component/marketOwnerDetail/marketOwnDetail";
function App() {
  const { isLoading } = useContext(Context);

  return (
    <>
      <SnackbarComponent />
      <Router>
        <Header />
        <Loader open={isLoading} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/free-trial" element={<SubAdminSignUp />} />
          <Route path="/market-own" element={<MarketOwn />} />
          <Route path="/market/:id" element={<MarketOwnerDetail />} />
          </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
