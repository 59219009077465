import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Modal, Button, Table } from "react-bootstrap"; // Importing Bootstrap Modal and Table components
import "./MarketOwnerDetail.css"; // Import the CSS file

const MarketOwnerDetail = () => {
  const { id } = useParams();
  const { getMarketVendorFilterApi } = useApiAxios();
  const [marketOwner, setMarketOwner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vendorData, setVendorData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedVendor, setSelectedVendor] = useState(null); // To store the selected vendor for detailed view

  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const dummyImagePath = "/path/to/dummy/image"; // Path to your fallback image

  useEffect(() => {
    const fetchMarketOwnerDetails = async () => {
      try {
        const response = await getMarketVendorFilterApi(id);
        const vendors = response.data.marketVendor;
        const marketsData = response.data.market;
        setMarketData(marketsData);
        setVendorData(vendors);
        setMarketOwner(response.data.market);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch market owner details.");
        setLoading(false);
      }
    };

    fetchMarketOwnerDetails();
  }, [id]);

  const handleVendorDetails = (vendor) => {
    setSelectedVendor(vendor);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVendor(null); // Clear selected vendor data
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const marketContentImageUrl = marketOwner?.marketContent
    ? `${staticImagePath}/${marketOwner?.marketContent?.bannerImage}`
    : dummyImagePath; // Fallback to dummy image if no marketContent

  return (
    <section className="market-owner-detail-container my-5">
      <div className="container">
        <div className="card shadow-lg">
          {/* Card header with background image */}
          <div
            className="card-header text-center text-white"
            style={{
              backgroundImage: `url(${marketContentImageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "250px", // Adjust the height based on your design
              position: "relative",
              marginTop:"50px",
            }}
          >
            <div className="overlay"></div>
            <h1 className="mb-3" style={{ position: "relative", zIndex: 1 }}>
              {marketOwner?.name || "Market Name"}
            </h1>
            <img
  className="img-fluid img-thumbnail rounded-circle"
  src={`${staticImagePath}/${marketOwner?.image || dummyImagePath}`}
  alt={marketOwner?.name}
  style={{
    maxWidth: "200px",        // Width of the image
    height: "200px",       // Height of the image should match width
    borderRadius: "50%",      // Ensures the image is round
    position: "relative", 
    zIndex: 1
  }}
  onError={(e) => {
    e.target.onerror = null;
    e.target.src = dummyImagePath;
  }}
/>

          </div>

          <div className="card-body">
            <div className="description-section mb-4">
              <h5 className="font-weight-bold">Market Description:</h5>             
              <div
              dangerouslySetInnerHTML={{
                __html: marketOwner?.marketContent?.description ||
                  "This is a placeholder description text."
              }}
            />
            </div>
            <div className="description-section mb-4">
              <h5 className="font-weight-bold">Market Name:</h5>             
              <div
              dangerouslySetInnerHTML={{
                __html: marketOwner?.name ||
                  "This is a Market Name text."
              }}
            />
            </div>

            <div className="category-subcategory-section mb-4 d-flex justify-content-between">
              <div className="categories">
                <h5 className="font-weight-bold">Categories:</h5>
                <div className="d-flex">
                  {marketOwner?.category?.length > 0 ? (
                    marketOwner?.category.map((cat) => (
                      <div key={cat._id} className="category-item">
                        <strong>{cat.name}</strong>
                      </div>
                    ))
                  ) : (
                    <p>No categories available.</p>
                  )}
                </div>
              </div>

              <div className="subcategories">
                <h5 className="font-weight-bold">Subcategories:</h5>
                <div className="d-flex">
                  {marketOwner?.subCategory?.length > 0 ? (
                    marketOwner?.subCategory.map((subCat) => (
                      <div key={subCat._id} className="subcategory-item">
                        <strong>{subCat.name}</strong>
                      </div>
                    ))
                  ) : (
                    <p>No subcategories available.</p>
                  )}
                </div>
              </div>
            </div>

            {/* Vendor List */}
            <div className="vendor-list mb-4">
              <h5 className="font-weight-bold">Vendors:</h5>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Business Name</th>
                    <th>Contact Person</th>
                    <th>Email</th>
                    <th>Contact Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {vendorData.map((vendor) => (
                    <tr key={vendor._id}>
                      <td>{vendor.businessName}</td>
                      <td>{vendor.contactPerson}</td>
                      <td>{vendor.email}</td>
                      <td>{vendor.contactNumber}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => handleVendorDetails(vendor)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Vendor Details */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" scrollable={true}>
        <Modal.Header >
          <Modal.Title>Vendor Details</Modal.Title>
          <button
          type="button"
          class="close"
          data-dismiss="modal"
          style={{ fontSize: "30px" }}
          aria-label="Close"
          onClick={handleCloseModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </Modal.Header>
        <Modal.Body>
          {selectedVendor ? (
            <Table striped bordered hover responsive>
              <tbody>
                <tr>
                  <td><strong>Business Name:</strong></td>
                  <td>{selectedVendor.businessName}</td>
                </tr>
                <tr>
                  <td><strong>Contact Person:</strong></td>
                  <td>{selectedVendor.contactPerson}</td>
                </tr>
                <tr>
                  <td><strong>Email:</strong></td>
                  <td>{selectedVendor.email}</td>
                </tr>
                <tr>
                  <td><strong>Contact Number:</strong></td>
                  <td>{selectedVendor.contactNumber}</td>
                </tr>
                <tr>
                  <td><strong>Business Description:</strong></td>
                  <td>{selectedVendor.businessDescription}</td>
                </tr>
               
                <tr>
                  <td><strong>Product Images:</strong></td>
                  <td>
                    {selectedVendor.productImage1 && (
                      <img
                        src={`${staticImagePath}/${selectedVendor.productImage1}`}
                        alt="Product"
                        style={{ maxWidth: "150px", marginRight: "10px" }}
                      />
                    )}
                    {selectedVendor.productImage2 && (
                      <img
                        src={`${staticImagePath}/${selectedVendor.productImage2}`}
                        alt="Product"
                        style={{ maxWidth: "150px" }}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <p>No vendor details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default MarketOwnerDetail;
