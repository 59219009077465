import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import useApiAxios from "../../api/useApiAxios";
import "./MarketOwn.css"; // Ensure this CSS file is linked for styling

const MarketOwn = () => {
  const { getMarketsOwned } = useApiAxios();

  // State for storing the response data, loading state, and pagination state
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [itemsPerPage] = useState(10); // Number of items per page

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        const response = await getMarketsOwned();
        setMarkets(response.data.data || []);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };

    fetchMarkets();
  }, []);

  // Calculate the indices of the markets to display on the current page
  const indexOfLastMarket = currentPage * itemsPerPage;
  const indexOfFirstMarket = indexOfLastMarket - itemsPerPage;
  const currentMarkets = markets.slice(indexOfFirstMarket, indexOfLastMarket);

  // Calculate the total number of pages
  const totalPages = Math.ceil(markets.length / itemsPerPage);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div className="loading-container">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const handleCardClick = (marketId) => {
    window.open(`/market/${marketId}`, "_blank"); // Adjusted to open market based on marketId
  };

  return (
    <div className="landing-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title headertext">Welcome to Market Owners</h1>
          <p className="hero-subtitle">
            Discover the market owners and the markets they own. Browse through
            various markets and find the best deals.
          </p>
        </div>
      </section>

      {/* Market Cards Section */}
      <section className="market-cards-section">
        <div className="market-owner-list">
          {currentMarkets.map((marketOwner) => (
            <div
              className="market-owner-card"
              key={marketOwner._id}
            >
              <h3 className="market-owner-name">{`${marketOwner.name}`}</h3>
              <div className="market-row">
                {marketOwner.markets.length > 0 ? (
                  marketOwner.markets.map((market, index) => (
                    <div
                      className="market-name oval-shape"  // Added 'oval-shape' class for styling
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();  // Prevent the click from propagating to the parent
                        handleCardClick(market._id);
                      }}
                    >
                      {market.name}
                    </div>
                  ))
                ) : (
                  <div className="no-markets">No markets available</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Pagination Section */}
      <div className="pagination-container">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-btn"
        >
          Previous
        </button>
        {[...Array(totalPages).keys()].map((page) => (
          <button
            key={page + 1}
            onClick={() => paginate(page + 1)}
            className={`pagination-btn ${
              currentPage === page + 1 ? "active" : ""
            }`}
          >
            {page + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-btn"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MarketOwn;
